.appear-h-enter-active, .appear-h-leave-active {
    transform: scaleY(1);
    transform-origin: top;
    max-height: 500px;
    transition: all 0.25s ease-in-out;
}

.appear-h-enter, .appear-h-leave-to
{
    transform: scaleY(0);
    max-height: 0;
}

.abs-enter-active, .abs-leave-active {
    transition: opacity .25s ease;
}

.abs-enter, .abs-leave-to /* .abs-leave-active below version 2.1.8 */
{
    opacity: 0;
    position: absolute;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .25s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}